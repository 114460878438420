// 尽调安排
<template>
  <div>
        <base-form
          :componentList="arrangementForm"
          :showBtns="false"
          :formAttrs="{
            model: expectDueDiligence,
            labelWidth: '150px',
          }"
          class="formStyle"
          ref="formExpect"
        />
  </div>
</template>
<script>
import { arrangementFormData } from '../utils/make-config'
import BaseForm from '@/components/common/base-form/base-form'
export default {
  props: {
    expectDueDiligence: Object
  },
  components: { BaseForm },
  data () {
    return {
      arrangementData: {} //
    }
  },
  computed: {
    arrangementForm () {
      return arrangementFormData(this)
    }
  },
  methods: {
    // 校验
    valiExpectDueDiligence () {
      let flag = false
      this.$refs.formExpect.validate(valid => {
        flag = valid
      })
      return flag
    }
  }

}
</script>
